import auth from 'helpers/DxionAuth';
import { runnerBaseUrl, runnerStatusUrl } from 'constants/defaultValues';
import { getCurrentSource } from './utils';
import { jobState } from './JobRunnerStates';

class JobRunner {
  async apiClient(props) {
    return auth.client(runnerBaseUrl)({ ...props });
  }

  async getJobStateAsync(jobInfo) {
    const path =
      jobInfo.case_id + '/' + jobInfo.media_id + '/' + jobInfo.job_id;
    const jobStatus = await this.apiClient({
      url: runnerStatusUrl + '/' + path,
    }).then((response) => response.data);
    // eslint-disable-next-line no-return-await
    return jobStatus;
  }

  async executeCreateJobAsync(config) {
    return this.apiClient({
      ...config,
      method: 'post',
    }).then((response) => response.data);
  }

  async createIOSURLJobAsync(caseId, mediaInfo, file) {
    const config = {
      url: '/segios/job/urlupload/' + caseId + '/' + mediaInfo.id,
      data: { ...file },
      params: {
        file_format: 'ply',
        scaling: -3,
        scan_type: mediaInfo.type.split('-')[1],
      },
    };
    return this.executeCreateJobAsync(config);
  }

  async createIOSUploadJobAsync(caseId, mediaInfo, file) {
    const data = new FormData();
    data.append('file', file);
    const config = {
      url: '/segios/job/upload/' + caseId + '/' + mediaInfo.id,
      data: data,
      params: {
        file_format: 'ply',
        scaling: -3,
        scan_type: mediaInfo.type.split('-')[1],
      },
    };
    return this.executeCreateJobAsync(config);
  }

  async createReconJobAsync(caseId, mediaInfo) {
    const config = {
      url: '/recon/job/' + caseId + '/' + mediaInfo.id,
      params: {
        src_media_id: mediaInfo.media_id,
        src_path_id: mediaInfo.path_id,
        type: mediaInfo.type.split('-')[1],
      },
    };
    return this.executeCreateJobAsync(config);
  }

  async createIOSEditJobAsync(caseId, mediaInfo, changes) {
    const config = {
      url: '/segios/job/edit/' + caseId + '/' + mediaInfo.id,
      data: changes,
      params: {
        scan_type: mediaInfo.type.split('-')[1],
        ref_id: mediaInfo.path,
      },
    };
    return this.executeCreateJobAsync(config);
  }

  async createCTUploadJobAsync(caseId, mediaInfo, file) {
    const data = new FormData();
    data.append('file', file);

    let param;
    if (mediaInfo.labelmap) {
      param = { labelmap: mediaInfo.labelmap };
    }

    const config = {
      url: '/segct/job/upload/' + caseId + '/' + mediaInfo.id,
      data: data,
      params: param,
    };
    return this.executeCreateJobAsync(config);
  }

  async createIOSMonitoringJobAsync(caseId, mediaInfo) {
    let config = {
      url: '/match/job/' + caseId + '/' + mediaInfo.id,
      data: { ...mediaInfo.refs },
    };
    if (mediaInfo.alignment) {
      let toothIdList = [];
      Object.entries(mediaInfo.alignment.tooth_ids).forEach(([id, value]) => {
        if (value.checked) {
          toothIdList.push(id);
        }
      });

      if (toothIdList.length > 0) {
        config.data.tooth_ids = toothIdList.join(',');
      }

      if (mediaInfo.alignment.full_mesh) {
        config.data.full_mesh = mediaInfo.alignment.full_mesh;
      }
    }
    console.log(config);
    return this.executeCreateJobAsync(config);
  }

  async newIOSJob(caseId, mediaInfo, file) {
    /* Create a new IOS job and check cases source */
    if (getCurrentSource().source === 'Medondo') {
      return this.createIOSURLJobAsync(caseId, mediaInfo, file);
    }
    return this.createIOSUploadJobAsync(caseId, mediaInfo, file);
  }

  async newIOSMonitoringJob(caseId, mediaInfo) {
    return this.createIOSMonitoringJobAsync(caseId, mediaInfo);
  }

  async newIOSEditJob(caseId, mediaInfo, changes) {
    return this.createIOSEditJobAsync(caseId, mediaInfo, changes);
  }

  async newCTJob(caseId, mediaInfo, file) {
    return this.createCTUploadJobAsync(caseId, mediaInfo, file);
  }

  async newReconJob(caseId, mediaInfo) {
    return this.createReconJobAsync(caseId, mediaInfo);
  }

  async getJobState(jobInfo) {
    const jobNewState = await this.getJobStateAsync(jobInfo);
    let retstate = jobNewState;
    switch (jobNewState.state) {
      case 'confirmed':
        retstate = jobState.CONFIRMED;
        break;
      case 'complete':
        retstate = jobState.COMPLETE;
        break;
      case 'running':
        retstate = jobState.RUNNING;
        break;
      case 'new':
      case 'ready':
      case 'preparing':
      case 'starting':
      case 'assigned':
      case 'pending':
        retstate = jobState.PENDING;
        break;
      default:
        retstate = jobState.FAILED;
        break;
    }
    return retstate;
  }
}

const jobClient = new JobRunner();

export { jobClient, jobState };
