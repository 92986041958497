import {
  CASES_GET_LIST,
  CASES_GET_LIST_SUCCESS,
  CASES_GET_LIST_ERROR,
  CASES_GET_LIST_WITH_FILTER,
  CASES_GET_LIST_WITH_ORDER,
  CASES_GET_LIST_SEARCH,
  CASES_ADD_ITEM,
  CASES_ADD_ITEM_SUCCESS,
  CASES_ADD_ITEM_ERROR,
  CASES_SELECTED_ITEMS_CHANGE,
  CASES_REMOVE_ITEM,
  CASES_REMOVE_ITEM_SUCCESS,
  CASES_REMOVE_ITEM_ERROR,
  CASES_UPDATE_ITEM,
  CASES_UPDATE_ITEM_SUCCESS,
  CASES_UPDATE_ITEM_ERROR,
} from '../contants';

export const getCaseList = () => ({
  type: CASES_GET_LIST,
});

export const getCaseListSuccess = (items) => ({
  type: CASES_GET_LIST_SUCCESS,
  payload: items,
});

export const getCaseListError = (error) => ({
  type: CASES_GET_LIST_ERROR,
  payload: error,
});

export const getCaseListWithFilter = (column, value) => ({
  type: CASES_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getCaseListWithOrder = (column, direction) => ({
  type: CASES_GET_LIST_WITH_ORDER,
  payload: { column, direction },
});

export const getCaseListSearch = (keyword) => ({
  type: CASES_GET_LIST_SEARCH,
  payload: keyword,
});

export const addCaseItem = (item) => ({
  type: CASES_ADD_ITEM,
  payload: item,
});

export const addCaseItemSuccess = (items) => ({
  type: CASES_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addCaseItemError = (error) => ({
  type: CASES_ADD_ITEM_ERROR,
  payload: error,
});

export const selectedCaseItemsChange = (selectedItems) => ({
  type: CASES_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});

export const removeCaseItem = (item) => ({
  type: CASES_REMOVE_ITEM,
  payload: item,
});

export const removeCaseItemSuccess = (items) => ({
  type: CASES_REMOVE_ITEM_SUCCESS,
  payload: items,
});

export const removeCaseItemError = (error) => ({
  type: CASES_REMOVE_ITEM_ERROR,
  payload: error,
});

export const updateCaseItem = (item) => ({
  type: CASES_UPDATE_ITEM,
  payload: item,
});

export const updateCaseItemSuccess = (items) => ({
  type: CASES_UPDATE_ITEM_SUCCESS,
  payload: items,
});

export const updateCaseItemError = (error) => ({
  type: CASES_UPDATE_ITEM_ERROR,
  payload: error,
});
