import axios from 'axios';
import { authConfig } from 'constants/defaultValues';
import { getCurrentUser, setCurrentUser } from 'helpers/utils';

class DxionAuth {
  constructor() {
    // console.log('In constructor...');
    this.authClient = axios.create({ baseURL: authConfig.auth_base_url });
    this.refreshTokenPromise = null;
  }

  async fetchTokenAsync(formData) {
    const retval = await this.authClient({
      url: authConfig.auth_token_url,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((response) => response.data);
    return retval;
  }

  async getUserInfoAsync(token) {
    const retval = await this.authClient({
      url: authConfig.user_info_url,
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).then((response) => response.data);
    return retval;
  }

  async refreshTokenAsync() {
    console.log('Refreshing token...');
    try {
      const response = await this.authClient({
        url: authConfig.auth_refresh_token_url,
        method: 'post',
        data: JSON.stringify({
          refresh_token: getCurrentUser().refresh_token,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        const userInfo = this.setUserAsync(response.data);
        return userInfo;
      }
      setCurrentUser();
    } catch (error) {
      setCurrentUser();
    }
    return null;
  }

  async setUserAsync(tokens) {
    console.log('Setting up user...');
    let userInfo = await this.getUserInfoAsync(tokens.access_token);
    userInfo.token = tokens.access_token;
    userInfo.expires_at = tokens.expires_at;
    userInfo.refresh_token = tokens.refresh_token;
    setCurrentUser(userInfo);
    return userInfo;
  }

  async signinWithCredentials(username, password) {
    // console.log('Signing in...');
    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    try {
      const tokens = await this.fetchTokenAsync(formData);
      return await this.setUserAsync(tokens);
    } catch (error) {
      let message = error.response.data.detail;
      throw new Error(message);
    }
  }

  async signOut() {
    clearTimeout(this.refreshTokenTimer);
    setCurrentUser();
    return {};
  }

  isRefreshTokenTime() {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const expiresAt = getCurrentUser()?.expires_at;
    return expiresAt / 1000 > currentTimestamp - 30;
  }

  async refreshToken() {
    let retval = null;
    retval = await this.refreshTokenAsync();
    return retval;
  }

  createUserWithEmailAndPassword(email, password) {
    // TODO: Implementation missing
    console.log('createUserWithEmailAndPassword', email, password);
  }

  sendPasswordResetEmail(email) {
    // TODO: Implementation missing
    console.log('sendPasswordResetEmail', email);
  }

  updatePassword(newPassword) {
    const value = {
      update_type: ['password'],
      payload: {
        password: newPassword,
      },
    };
    const client = this.client(authConfig.auth_base_url);
    return client({
      url: authConfig.user_config_url,
      method: 'put',
      data: value,
    }).then((response) => response.data);
  }

  updateUserProfile(value) {
    const data = {
      update_type: ['userinfo'],
      payload: {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
      },
    };
    const client = this.client(authConfig.auth_base_url);
    return client({
      url: authConfig.user_config_url,
      method: 'put',
      data: data,
    }).then((reponse) => reponse.data);
  }

  async getUserProfileAsync() {
    const client = this.client(authConfig.auth_base_url);
    return client({
      url: authConfig.user_config_url,
      method: 'get',
    }).then((response) => response.data);
  }

  getCurrentUser() {
    if (this.isRefreshTokenTime()) {
      this.refreshToken();
    }
    return getCurrentUser();
  }

  client(baseURL, props) {
    try {
      const client = axios.create({
        ...props,
        baseURL: baseURL,
      });
      client.interceptors.request.use(
        async (config) => {
          let newConfig = { ...config };
          const tokens = getCurrentUser();
          const timeNow = Date.now() / 1000;
          const accessTokenValid = timeNow < (tokens.expires_at ?? timeNow);
          // console.log('Valid token', accessTokenValid);
          // console.log(
          //   'Next refresh in: ',
          //   timeNow - (tokens.expires_at ?? timeNow),
          // );
          if (accessTokenValid) {
            newConfig.headers.Authorization = 'Bearer ' + tokens.token;
            return newConfig;
          }
          this.refreshTokenPromise ??= this.refreshTokenAsync();
          const newTokens = await this.refreshTokenPromise;
          // console.log('New token... ', newTokens.token);
          if (newTokens) {
            newConfig.headers.Authorization = 'Bearer ' + newTokens.token;
          }
          this.refreshTokenPromise = null;
          return newConfig;
        },
        (error) => {
          // Do something with request error
          return Promise.reject(error);
        },
      );
      return client;
    } catch (error) {
      console.log('api client error => ' + error);
      return null;
    }
  }
}

const auth = new DxionAuth();

export default auth;
