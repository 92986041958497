import { jobState } from 'helpers/JobRunnerStates';
import { mediaState } from 'helpers/MediaStates';
import { MIMETYPE_EXT_MAP } from 'constants/defaultValues';

const isJobSuccess = (state) => {
  return (
    state === jobState.COMPLETE ||
    state === jobState.NEW ||
    state === mediaState.NEW ||
    state === mediaState.CONFIRMED
  );
};

const getExtFromContentType = (contentType) => {
  let retval = MIMETYPE_EXT_MAP[contentType];
  return retval;
};

const getContentTypeFromExt = (filename) => {
  let retval = '';
  const fileExt = filename.split('.').pop();
  Object.entries(MIMETYPE_EXT_MAP).forEach(([mimeType, ext]) => {
    if (ext === fileExt) {
      retval = mimeType;
    }
  });
  return retval;
};

const isValidMediaType = (mimeType) => {
  return MIMETYPE_EXT_MAP[mimeType] !== undefined;
};

export {
  isJobSuccess,
  getExtFromContentType,
  getContentTypeFromExt,
  isValidMediaType,
};
