import auth from 'helpers/DxionAuth';
import { DTXRegistry } from './DTXRegistry';
import { medondoCasesRegistry } from 'constants/defaultValues';

class MedondoRegistry extends DTXRegistry {
  constructor() {
    super();
  }

  async apiClient(props) {
    return auth.client(medondoCasesRegistry)({ ...props });
  }

  async validate() {
    // eslint-disable-next-line no-return-await
    return await this.apiClient({
      url: '/validate',
      method: 'get',
    }).then((response) => {
      return response.data;
    });
  }

  async listCases() {
    // eslint-disable-next-line no-return-await
    return await this.apiClient({
      url: '/',
      method: 'get',
    }).then((response) => {
      return response.data;
    });
  }

  async readCase(caseId) {
    // eslint-disable-next-line no-return-await
    return await this.apiClient({
      url: '/' + caseId,
      method: 'get',
    }).then((response) => response.data);
  }

  async listMediaInCase(caseId) {
    const url = '/' + caseId + '/storage';
    const response = await this.apiClient({
      url: url,
      method: 'get',
    });
    const dataList = response.data.map((data) => {
      return {
        id: data.id,
        title: data.title,
        filename: data.filename,
        path: data.path,
        created: data.created_time,
        mediatype: data?.content_type,
        scantype: data?.type,
      };
    });
    return dataList;
  }

  async get_file_storage(path) {
    const url = '/storage/' + encodeURI(path);
    const data = await this.apiClient({
      url: url,
      method: 'get',
    }).then((response) => response.data);
    // eslint-disable-next-line no-return-await
    return await data;
  }

  get_file_url({ path }) {
    return medondoCasesRegistry + '/content/' + encodeURI(path);
  }
}

const cases = new MedondoRegistry();

export { cases, MedondoRegistry };
