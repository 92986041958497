export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const canvasOffsetHeightFullScreen = 90 + 60;
export const toothNumbersFDIUpper = [
  18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28,
];
export const toothNumbersFDILower = [
  38, 37, 36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
];
export const toothNumbersFDIAll =
  toothNumbersFDIUpper.concat(toothNumbersFDILower);

export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

let authBaseURL = '';
let apiBaseURL = '';

if (window.env?.REACT_APP_AUTH_ENDPOINT) {
  // Check if window env vars are setup, used in run time
  authBaseURL = window.env.REACT_APP_AUTH_ENDPOINT;
  apiBaseURL = window.env.REACT_APP_API_ENDPOINT;
} else if (process.env) {
  // Use the processes environment during development and testing
  authBaseURL = process.env.REACT_APP_AUTH_ENDPOINT;
  apiBaseURL = process.env.REACT_APP_API_ENDPOINT;
}

export const authConfig = {
  auth_base_url: authBaseURL,
  auth_token_url: '/auth/login',
  auth_refresh_token_url: '/auth/refresh',
  user_info_url: '/auth/userinfo',
  user_config_url: '/auth/users/',
  plugins_url: '/auth/plugins',
};

export const apiEndpoint = apiBaseURL;

export const adminRoot = '/app';
export const searchPath = `${adminRoot}/#`;
export const casesPath = `${adminRoot}/cases/`;
export const mediaPath = `${adminRoot}/cases/media/`;
export const viewerPath = `${adminRoot}/cases/media/viewer/`;

export const userRoot = '/user';
export const userLoginUrl = userRoot + '/login';

export const currentUser = {
  uid: 0,
  title: '',
};

export const userCommon = {
  role: UserRole.Admin,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.redruby';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const isUserRegistryActive = false;

export const baseMediaURL = apiEndpoint + '/media';

export const hostnameCasesRegistry = apiEndpoint;
export const endpointCasesRegistry = apiEndpoint + '/cases/';
export const medondoCasesRegistry = apiEndpoint + '/medondo/';

// Runners
export const caseMediaTypes = [
  'IOS-Upper',
  'IOS-Lower',
  'Monitoring-Upper',
  'Monitoring-Lower',
  'Reconstruct-Upper',
  'Reconstruct-Lower',
];

export const runnerBaseUrl = apiEndpoint;
export const runnerEndpointByType = {
  monitoring: '/match',
  'monitoring-upper': '/match',
  'monitoring-lower': '/match',
  ios: '/segios/segios',
  'ios-upper': '/segios',
  'ios-lower': '/segios',
  'reconstruct-upper': '/recon',
  'reconstruct-lower': '/recon',
};
export const runnerStatusUrl = '/jobs/status/';

export const baseSourceList = ['Dentexion'];
export const sourceOptions = {
  Dentexion: {
    cases: {
      new: true,
      edit: true,
      remove: true,
    },
  },
  Medondo: {
    cases: {
      new: false,
      edit: false,
      remove: false,
    },
  },
};

export const enabledReportRegions = ['all', 'occlusal', 'oral', 'buccal'];

export const mediaDefaultUploadFileTypes = '.stl, .ply, .obj';

export const mediaIOSUploadFileTypes = '.stl, .ply, .obj';

export const MIMETYPE_STL = 'model/stl';
export const MIMETYPE_PLY = 'model/vnd.ply';
export const MIMETYPE_OBJ = 'model/obj';

export const MIMETYPES = {
  STL: MIMETYPE_STL,
  PLY: MIMETYPE_PLY,
  OBJ: MIMETYPE_OBJ,
};

export const FILEEXT_STL = 'stl';
export const FILEEXT_PLY = 'ply';
export const FILEEXT_OBJ = 'obj';

export const FILEEXT = {
  stl: FILEEXT_STL,
  ply: FILEEXT_PLY,
  obj: FILEEXT_OBJ,
};

export const MIMETYPE_EXT_MAP = {
  'model/stl': FILEEXT_STL,
  'model/vnd.ply': FILEEXT_PLY,
  'model/obj': FILEEXT_OBJ,
};

export const DEFAULT_DECIMALS = 2;
export const DEFAULT_DECIMALS_PERIODONTAL = 1;
export const DEFAULT_DECIMALS_ORTHODONTICS = 2;
export const DEFAULT_DECIMALS_SCIENTIFIC = 3;
