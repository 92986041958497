import auth from 'helpers/DxionAuth';
import { baseMediaURL } from 'constants/defaultValues';

class MediaRegistry {
  async apiClient(props) {
    return auth.client(baseMediaURL + '/media')({ ...props });
  }

  async getPathInfo(path) {
    // eslint-disable-next-line no-return-await
    return await this.apiClient({
      url: path,
      method: 'get',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async getPathInfoById(caseId, mediaId, keyPath) {
    const path = caseId + '/' + mediaId + keyPath;
    return this.getPathInfo(path);
  }

  async getPathAsZip(path, options = {}) {
    // eslint-disable-next-line no-return-await
    return await this.apiClient({
      url: path,
      method: 'get',
      headers: {
        Accept: 'application/x-zip',
      },
      responseType: 'blob',
      ...options,
    });
  }

  async getAsZip(caseId, mediaInfo, fileListInfo) {
    let url = '/files/';
    url += caseId + '/';
    url += mediaInfo.mediaId + '/';
    url += mediaInfo.pathId;
    return this.getPathAsZip(url, {
      method: 'post',
      data: { ...fileListInfo },
    });
  }

  async getPathAsFile(path) {
    return this.apiClient({
      url: path,
      method: 'get',
      headers: {
        Accept: 'application/octet-stream',
      },
    });
  }
}

const mediaClient = new MediaRegistry();

export { mediaClient };
