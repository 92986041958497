import auth from 'helpers/DxionAuth';
import { authConfig } from 'constants/defaultValues';

const fetchAPIAsync = async (props) => {
  const apiClient = auth.client(
    authConfig.auth_base_url + '/' + authConfig.plugins_url,
  );
  // eslint-disable-next-line no-return-await
  return await apiClient({
    ...props,
  }).then((response) => {
    return response.data;
  });
};

const getEnabledPlugin = () => {
  return fetchAPIAsync({
    url: '/',
    method: 'get',
  });
};

const getPluginSettings = (pluginName) => {
  return fetchAPIAsync({
    url: '/' + pluginName + '/settings',
    method: 'get',
  });
};

const setPluginSettings = (pluginName, settings) => {
  return fetchAPIAsync({
    url: '/' + pluginName + '/settings',
    method: 'post',
    data: { ...settings },
  });
};

const getPluginEndpoint = (pluginName, endpoint) => {
  return fetchAPIAsync({
    url: '/' + pluginName + '/' + endpoint,
    method: 'get',
  });
};

export {
  getEnabledPlugin,
  getPluginSettings,
  setPluginSettings,
  getPluginEndpoint,
};
