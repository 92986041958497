/* FIXME: use redux-toolkit and avoid a lot of the boiler-plate in reducers */

/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_ERROR = 'UPDATE_USER_INFO_SUCCES';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* CASES */
export const CASES_GET_LIST = 'CASES_GET_LIST';
export const CASES_GET_LIST_SUCCESS = 'CASES_GET_LIST_SUCCESS';
export const CASES_GET_LIST_ERROR = 'CASES_GET_LIST_ERROR';
export const CASES_GET_LIST_WITH_FILTER = 'CASES_GET_LIST_WITH_FILTER';
export const CASES_GET_LIST_WITH_ORDER = 'CASES_GET_LIST_WITH_ORDER';
export const CASES_GET_LIST_SEARCH = 'CASES_GET_LIST_SEARCH';
export const CASES_ADD_ITEM = 'CASES_ADD_ITEM';
export const CASES_ADD_ITEM_SUCCESS = 'CASES_ADD_ITEM_SUCCESS';
export const CASES_ADD_ITEM_ERROR = 'CASES_ADD_ITEM_ERROR';
export const CASES_SELECTED_ITEMS_CHANGE = 'CASES_SELECTED_ITEMS_CHANGE';
export const CASES_REMOVE_ITEM = 'CASES_REMOVE_ITEM';
export const CASES_REMOVE_ITEM_SUCCESS = 'CASES_REMOVE_ITEM_SUCCESS';
export const CASES_REMOVE_ITEM_ERROR = 'CASES_REMOVE_ITEM_ERROR';
export const CASES_UPDATE_ITEM = 'CASES_UPDATE_ITEM';
export const CASES_UPDATE_ITEM_SUCCESS = 'CASES_UPDATE_ITEM_SUCCESS';
export const CASES_UPDATE_ITEM_ERROR = 'CASES_UPDATE_ITEM_ERROR';
