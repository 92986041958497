import { cases } from 'helpers/Registry/DTXRegistry';
import { mediaState } from './MediaStates';
import { jobClient } from './JobRunner';

export const jobState = Object.freeze({
  // Media Status
  NEW: 'NEW',
  CONFIRMED: 'CONFIRMED',
  // Job Status
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
});

export const fetchMediaStateAsync = async (caseId, mediaItem) => {
  const state = await cases
    .getMediaInfo(caseId, mediaItem.id)
    .then((mediaInfo) => {
      const newMediaState = mediaInfo.state;
      switch (newMediaState) {
        case mediaState.NEW:
          return mediaState.NEW;
        case mediaState.CONFIRMED:
          return mediaState.CONFIRMED;
        default:
          console.log('Unhandled media state', newMediaState);
          return jobState.FAILED;
      }
    });
  return state;
};

export const fetchJobStateAsync = async (caseId, mediaItem) => {
  const state = await jobClient.getJobState({
    case_id: caseId,
    media_id: mediaItem.id,
    job_id: mediaItem.path,
  });
  return state;
};

export const fetchAndSetStatusAsync = async (caseId, mediaItem) => {
  let retStatus = await fetchJobStateAsync(caseId, mediaItem);
  if (retStatus === jobState.COMPLETE) {
    retStatus = await fetchMediaStateAsync(caseId, mediaItem);
  }
  return retStatus;
};
