import {
  CASES_GET_LIST,
  CASES_GET_LIST_SUCCESS,
  CASES_GET_LIST_ERROR,
  CASES_GET_LIST_WITH_FILTER,
  CASES_GET_LIST_WITH_ORDER,
  CASES_GET_LIST_SEARCH,
  CASES_ADD_ITEM,
  CASES_ADD_ITEM_SUCCESS,
  CASES_ADD_ITEM_ERROR,
  CASES_SELECTED_ITEMS_CHANGE,
  CASES_REMOVE_ITEM,
  CASES_REMOVE_ITEM_SUCCESS,
  CASES_REMOVE_ITEM_ERROR,
  CASES_UPDATE_ITEM,
  CASES_UPDATE_ITEM_SUCCESS,
  CASES_UPDATE_ITEM_ERROR,
} from '../contants';

import { getUserListOrder, orderCaseList } from 'helpers/utils';

const INIT_STATE = {
  allCaseItems: null,
  caseItems: null,
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: getUserListOrder(),
  loaded: false,
  labels: [
    { label: 'IOS-Upper', color: 'secondary' },
    { label: 'IOS-Lower', color: 'secondary' },
    { label: 'Monitoring-Upper', color: 'primary' },
    { label: 'Monitoring-Lower', color: 'primary' },
    { label: 'CT', color: 'info' },
  ],
  orderColumns: [
    { column: 'title', label: 'Title Asc', direction: 'ASC' },
    { column: 'title', label: 'Title Desc', direction: 'DESC' },
    // { column: 'category', label: 'Category' },
    // { column: 'status', label: 'Status' },
    // { column: 'label', label: 'Label' },
    { column: 'created_time', label: 'Date Asc', direction: 'ASC' },
    { column: 'created_time', label: 'Date Desc', direction: 'DESC' },
  ],
  // categories: ['Flexbox', 'Sass', 'React'],
  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CASES_GET_LIST:
      return { ...state, loaded: false };

    case CASES_GET_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        allCaseItems: action.payload,
        caseItems: orderCaseList(action.payload),
      };

    case CASES_GET_LIST_ERROR:
      return { ...state, loaded: true, error: action.payload };

    case CASES_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return {
          ...state,
          loaded: true,
          caseItems: state.allCaseItems,
          filter: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      const filteredItems = state.allCaseItems.filter(
        (item) => item[action.payload.column] === action.payload.value,
      );
      return {
        ...state,
        loaded: true,
        caseItems: orderCaseList(filteredItems),
        filter: {
          column: action.payload.column,
          value: action.payload.value,
        },
      };

    case CASES_GET_LIST_WITH_ORDER:
      if (action.payload === '') {
        return {
          ...state,
          loaded: true,
          caseItems: state.caseItems,
          orderColumn: null,
        };
      }

      return {
        ...state,
        loaded: true,
        caseItems: orderCaseList(state.caseItems),
        orderColumn: state.orderColumns.find((x) => {
          return (
            x.column === action.payload.column &&
            x.direction === action.payload.direction
          );
        }),
      };

    case CASES_GET_LIST_SEARCH: {
      if (action.payload === '') {
        return {
          ...state,
          caseItems: state.allCaseItems,
          searchKeyword: '',
        };
      }
      const keyword = action.payload.toLowerCase();
      const searchItems = state.allCaseItems.filter(
        (item) => item.title.toLowerCase().indexOf(keyword) > -1,
      );
      return {
        ...state,
        loaded: true,
        caseItems: orderCaseList(searchItems),
        searchKeyword: action.payload,
      };
    }
    case CASES_ADD_ITEM:
      return { ...state, loaded: false };

    case CASES_ADD_ITEM_SUCCESS: {
      return {
        ...state,
        loaded: true,
        allCaseItems: action.payload,
        caseItems: orderCaseList(action.payload),
      };
    }
    case CASES_ADD_ITEM_ERROR:
      return { ...state, loaded: true, error: action.payload };

    case CASES_SELECTED_ITEMS_CHANGE:
      return { ...state, loaded: true, selectedItems: action.payload };

    case CASES_REMOVE_ITEM:
      return { ...state, loaded: false };

    case CASES_REMOVE_ITEM_SUCCESS:
      return {
        ...state,
        loaded: true,
        allCaseItems: action.payload,
        caseItems: orderCaseList(action.payload),
      };

    case CASES_REMOVE_ITEM_ERROR:
      return { ...state, loaded: true, error: action.payload };

    case CASES_UPDATE_ITEM:
      return { ...state, loaded: false };

    case CASES_UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        loaded: true,
        allCaseItems: action.payload,
        caseItems: orderCaseList(action.payload),
      };

    case CASES_UPDATE_ITEM_ERROR:
      return { ...state, loaded: true, error: action.payload };

    default:
      return { ...state };
  }
};
