import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { cases } from 'helpers/Registry/DTXRegistry';
import { cases as medondo } from 'helpers/Registry/MedondoRegistry';

import {
  CASES_GET_LIST,
  CASES_ADD_ITEM,
  CASES_REMOVE_ITEM,
  CASES_UPDATE_ITEM,
} from '../contants';

import {
  getCaseListSuccess,
  getCaseListError,
  addCaseItemSuccess,
  addCaseItemError,
  removeCaseItemSuccess,
  removeCaseItemError,
  updateCaseItemSuccess,
  updateCaseItemError,
} from './actions';
import { getCurrentSource } from 'helpers/utils';

const getCaseLabel = (caseType) => {
  switch (caseType) {
    case 'IOS-OK':
      return {
        label: 'IOS-OK',
        labelColor: 'primary',
      };
    case 'IOS-UK':
      return {
        label: 'IOS-UK',
        labelColor: 'secondary',
      };
    default:
      return {};
  }
};

// eslint-disable-next-line no-unused-vars
const formatCase = (id, data) => {
  let output = {
    id: id,
    title: data.title,
    detail: data.description,
    createDate: data.created_time,
    status: 'PENDING',
    ...getCaseLabel(data.type),
  };
  return output;
};

const getCaseListAsync = async () => {
  const currentUser = getCurrentSource();
  let client = null;
  if (currentUser) {
    client = cases;
    if (currentUser.source === 'Medondo') {
      client = medondo;
    }
    // eslint-disable-next-line no-return-await
    return await client
      .listCases()
      .then((response) => response)
      .catch(() => {
        return { message: 'Failed to collect case list.' };
      });
  }
  return { message: 'Invalid user' };
};

function* getCaseListItems() {
  try {
    const response = yield call(getCaseListAsync);
    if (!response.message) {
      yield put(getCaseListSuccess(response));
    } else {
      yield put(getCaseListError(response.message));
    }
  } catch (error) {
    yield put(getCaseListError(error));
  }
}

const addCaseItemAsync = async (item) => {
  // eslint-disable-next-line no-return-await
  return await cases
    .createCase(item)
    .then((response) => response)
    .catch((error) => error);
};

function* addCaseItem({ payload }) {
  try {
    const response = yield call(addCaseItemAsync, payload);
    if (!response.message) {
      const listItems = yield call(getCaseListAsync);
      if (!listItems.message) {
        yield put(addCaseItemSuccess(listItems));
      }
    } else {
      yield put(addCaseItemError(response.message));
    }
  } catch (error) {
    yield put(addCaseItemError(error));
  }
}

const removeCaseItemAsync = async (item) => {
  // eslint-disable-next-line no-return-await
  return await cases
    .removeCase(item)
    .then((response) => response)
    .catch((error) => error);
};

function* removeCaseItem({ payload }) {
  try {
    const response = yield call(removeCaseItemAsync, payload);
    if (!response.message) {
      const listItems = yield call(getCaseListAsync);
      if (!listItems.message) {
        yield put(removeCaseItemSuccess(listItems));
      }
    } else {
      yield put(removeCaseItemError(response.message));
    }
  } catch (error) {
    yield put(removeCaseItemError(error));
  }
}

const updateCaseItemAsync = async (item) => {
  // eslint-disable-next-line no-return-await
  return await cases
    .updateCase(item)
    .then((response) => response)
    .catch((error) => error);
};

function* updateCaseItem({ payload }) {
  try {
    const response = yield call(updateCaseItemAsync, payload);
    if (!response.message) {
      const listItems = yield call(getCaseListAsync);
      if (!listItems.message) {
        yield put(updateCaseItemSuccess(listItems));
      }
    } else {
      yield put(updateCaseItemError(response.message));
    }
  } catch (error) {
    yield put(updateCaseItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(CASES_GET_LIST, getCaseListItems);
}

export function* watchAddItem() {
  yield takeEvery(CASES_ADD_ITEM, addCaseItem);
}

export function* watchRemoveItem() {
  yield takeEvery(CASES_REMOVE_ITEM, removeCaseItem);
}

export function* watchUpdateItem() {
  yield takeEvery(CASES_UPDATE_ITEM, updateCaseItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchAddItem),
    fork(watchRemoveItem),
    fork(watchUpdateItem),
  ]);
}
