import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  baseSourceList,
  sourceOptions,
} from 'constants/defaultValues';

import { getPluginSettings } from './PluginsHelper';
import { cases as MedondoRegistry } from './Registry/MedondoRegistry';
import { cases as DentexionRegistry } from './Registry/DTXRegistry';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getFormatDate = (dateValue) => {
  const date = dateValue.toISOString().split('T')[0];
  let retval = date + ' ';
  retval += dateValue.getHours() + ':' + dateValue.getMinutes();
  return retval;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log('getCurrentRadius -> error', error);
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log('setCurrentRadius -> error', error);
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage'),
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log('getCurrentLanguage -> error', error);
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log('setCurrentLanguage -> error', error);
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('current_user') != null
        ? JSON.parse(localStorage.getItem('current_user'))
        : null;
  } catch (error) {
    console.log('getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('current_user');
    }
  } catch (error) {
    console.log('setCurrentUser -> error', error);
  }
};

/* FIXME: Move the source list and selection to a redux state */
export const getAvailableSources = async () => {
  let sourceList = [...baseSourceList];
  try {
    const isMedondoActive = await getPluginSettings('medondo').then(
      (settings) => settings.active,
    );
    // TODO: Make it dynamic so we have a endpoint to get
    //       all plugins source type wich are active
    if (isMedondoActive) {
      const isValid = await MedondoRegistry.validate()
        .then((valid) => valid)
        .catch(() => null);
      if (isValid) {
        sourceList.push('Medondo');
      }
    }
  } catch (error) {
    console.log('getAvailableSources -> error: ', error);
  }
  return sourceList;
};

export const getCurrentSource = () => {
  let source = null;
  let options = {};
  try {
    source =
      localStorage.getItem('current_source') != null
        ? localStorage.getItem('current_source')
        : 'Dentexion';
    if (source) {
      options = { ...sourceOptions[source] };
    }
  } catch (error) {
    console.log('getCurrentSource -> error', error);
    source = null;
  }
  return {
    source: source,
    options: options,
  };
};

export const getRegistryBySource = () => {
  const selectedSource = getCurrentSource();
  switch (selectedSource.source.toLowerCase()) {
    case 'dentexion':
      return DentexionRegistry;
    case 'medondo':
      return MedondoRegistry;
    default:
      return null;
  }
};

export const setCurrentSource = (source) => {
  try {
    if (source) {
      localStorage.setItem('current_source', source);
    } else {
      localStorage.removeItem('current_source');
    }
  } catch (error) {
    console.log('setCurrentSource -> error', error);
  }
};

const defaulListOrder = {
  column: 'created_time',
  label: 'Date Desc',
  direction: 'DESC',
};

export const getUserListOrder = () => {
  var userListOrder = null;
  try {
    userListOrder =
      localStorage.getItem('user_list_order') != null
        ? JSON.parse(localStorage.getItem('user_list_order'))
        : { ...defaulListOrder };
  } catch (error) {
    console.log('getUserListOrder -> error', error);
  }
  return userListOrder;
};

export const setUserListOrder = (sorting) => {
  try {
    if (sorting) {
      localStorage.setItem('user_list_order', JSON.stringify(sorting));
    } else {
      localStorage.removeItem('user_list_order');
    }
  } catch (error) {
    console.log('setUserListOrder -> error', error);
  }
};

export const getSortedList = (list, column, direction) => {
  // eslint-disable-next-line no-case-declarations
  let sortedItems = list.sort((a, b) => {
    if (a[column] < b[column]) return -1;
    if (a[column] > b[column]) return 1;
    return 0;
  });

  return direction === 'DESC' ? sortedItems.reverse() : sortedItems;
};

export const orderCaseList = (caseItemList) => {
  const userOrder = getUserListOrder();
  let sortedList = caseItemList;
  if (userOrder) {
    sortedList = getSortedList(
      caseItemList,
      userOrder.column,
      userOrder.direction,
    );
  }
  return sortedList;
};
