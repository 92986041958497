import auth from 'helpers/DxionAuth';
import { mediaClient } from 'helpers/MediaRegistry';
import { jobClient } from '../JobRunner';
import { endpointCasesRegistry, baseMediaURL } from 'constants/defaultValues';
import { getContentTypeFromExt } from 'components/applications/utils';

class DTXRegistry {
  async apiClient(props) {
    return auth.client(endpointCasesRegistry)({ ...props });
  }

  async listCases() {
    // eslint-disable-next-line no-return-await
    return await this.apiClient({
      url: 'cases/',
      method: 'get',
    }).then((response) => response.data);
  }

  async readCase(caseId) {
    // eslint-disable-next-line no-return-await
    return await this.apiClient({
      url: 'cases/' + caseId,
      method: 'get',
    }).then((response) => response.data);
  }

  async createCase(item) {
    // eslint-disable-next-line no-return-await
    return await this.apiClient({
      url: 'cases/',
      method: 'post',
      params: item,
    }).then((response) => response.data);
  }

  async updateCase(item) {
    // eslint-disable-next-line no-return-await
    return await this.apiClient({
      url: 'cases/' + item.id,
      method: 'put',
      params: item,
    })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async removeCase(item) {
    // eslint-disable-next-line no-return-await
    return await this.apiClient({
      url: 'cases/' + item.id,
      method: 'delete',
    })
      .then((response) => response)
      .catch((error) => error);
  }

  /* ################  Media  ###################### */
  executeGetMediaListAsync = async (url) => {
    // eslint-disable-next-line no-return-await
    const mediaList = await this.apiClient({
      url: url,
      method: 'get',
    }).then((response) => response.data.media);
    return mediaList;
  };

  executeGetMediaStatsAsync = async (caseId, mediaItemInfo) => {
    if (mediaItemInfo.path == null) {
      return { stats: null };
    }
    const matchTypes = [/^ios/, /^ct/];
    let shouldGetInputInfo = false;
    matchTypes.forEach((match) => {
      if (!shouldGetInputInfo && match.test(mediaItemInfo.type)) {
        shouldGetInputInfo = true;
      }
    });
    let path = caseId + '/' + mediaItemInfo.id;
    if (mediaItemInfo?.refs !== null && shouldGetInputInfo) {
      const refPath = mediaItemInfo.refs.edit[0].path;
      path += '/' + refPath;
    } else {
      path += '/' + mediaItemInfo.path;
    }
    if (shouldGetInputInfo) path += '/input';
    const pathStats = await mediaClient.getPathInfo(path);
    return { stats: { ...pathStats } };
  };

  executeGetMediaStatsFromListAsync = async (caseId, mediaList) => {
    const mediaItemList = await Promise.all(
      mediaList.map((mediaItemInfo) => {
        return this.executeGetMediaStatsAsync(caseId, mediaItemInfo).then(
          (response) => ({ ...response, info: mediaItemInfo }),
        );
      }),
    );
    return mediaItemList;
  };

  async listMediaInCase(caseId) {
    const caseMediaListInfo = await this.executeGetMediaListAsync(
      'cases/' + caseId + '/media/',
    );
    const caseMediaListStats = await this.executeGetMediaStatsFromListAsync(
      caseId,
      caseMediaListInfo,
    );
    const dataList = caseMediaListStats.map((data) => {
      let fileStats = {
        filename: null,
        created: null,
        mediatype: null,
      };
      if (data.stats) {
        fileStats.filename = data.stats.dirs[0];
        fileStats.mediatype = getContentTypeFromExt(data.stats.dirs[0]);
        fileStats.created = data.stats.created;
      }
      return {
        id: data.info.id,
        title: data.info.title,
        scantype: data.info.type,
        path: data.info.path,
        state: data.info.state,
        ...fileStats,
      };
    });
    return dataList;
  }

  async getMediaInfo(caseId, mediaId) {
    const url = '/cases/' + caseId + '/media/' + mediaId;
    const mediaInfo = await this.apiClient({
      url: url,
      method: 'get',
    }).then((response) => response.data);
    // eslint-disable-next-line no-return-await
    return await mediaInfo;
  }

  async executeRegisterMedia(config) {
    /* Perform registration to the backend */
    return this.apiClient({
      ...config,
      method: 'put',
    }).then((response) => response.data);
  }

  /* IOS Media */

  async registerIOSMediaAsync(caseId, mediaInfo, jobInfo) {
    /* Registration of segios job into media */
    const config = {
      url: 'cases/' + caseId + '/media/' + mediaInfo.id,
      data: {
        title: mediaInfo.title,
        type: mediaInfo.type.toLowerCase(),
        path: jobInfo.id,
      },
    };
    return this.executeRegisterMedia(config);
  }

  async registerIOSEditMediaAsync(caseId, mediaInfo, jobInfo) {
    const prevPath = { path: mediaInfo.path };
    let newMediaInfo = { ...mediaInfo };
    if (newMediaInfo.refs == null) {
      newMediaInfo.refs = {
        edit: [prevPath],
      };
    } else if (newMediaInfo.refs.edit) {
      newMediaInfo.refs.edit.push(prevPath);
    }

    const config = {
      url: 'cases/' + caseId + '/media/' + mediaInfo.id,
      data: {
        title: newMediaInfo.title,
        type: newMediaInfo.type.toLowerCase(),
        path: jobInfo.id,
        refs: { ...newMediaInfo.refs },
      },
    };
    return this.executeRegisterMedia(config);
  }

  async createIOSJobAsync(caseId, mediaInfo, file) {
    // TODO: remove me?
    // eslint-disable-next-line no-return-await
    return await jobClient.newIOSJob(caseId, mediaInfo, file);
  }

  async createIOSEditJobAsync(caseId, mediaInfo, changes) {
    // TODO: remove me?
    // eslint-disable-next-line no-return-await
    return await jobClient.newIOSEditJob(caseId, mediaInfo, changes);
  }

  async addIOSMedia(caseId, mediaInfo, file) {
    // Start a new IOS segementation job
    const jobInfo = await this.createIOSJobAsync(caseId, mediaInfo, file);
    return this.registerIOSMediaAsync(caseId, mediaInfo, jobInfo);
  }

  async addIOSEditMedia(caseId, mediaInfo, changes) {
    // Start a new IOS segmentation job with changes
    const jobInfo = await this.createIOSEditJobAsync(
      caseId,
      mediaInfo,
      changes,
    );
    return this.registerIOSEditMediaAsync(caseId, mediaInfo, jobInfo);
  }

  /* Monitoring Media */

  async registerIOSMonitoringMediaAsync(caseId, mediaInfo, jobInfo) {
    /* Registration of the IOS monitoring into media */
    const config = {
      url: 'cases/' + caseId + '/media/' + mediaInfo.id,
      data: {
        title: mediaInfo.title,
        type: mediaInfo.type.toLowerCase(),
        path: jobInfo.id,
        refs: { ...mediaInfo.refs },
      },
    };
    return this.executeRegisterMedia(config);
  }

  async createIOSMonitoringJobAsync(caseId, mediaInfo) {
    // TODO: remove me?
    // eslint-disable-next-line no-return-await
    return await jobClient.newIOSMonitoringJob(caseId, mediaInfo);
  }

  async addIOSMonitoringMedia(caseId, mediaInfo) {
    /* Start a new IOS monitoring job */
    const jobInfo = await this.createIOSMonitoringJobAsync(caseId, mediaInfo);
    return this.registerIOSMonitoringMediaAsync(caseId, mediaInfo, jobInfo);
  }

  /* Recon Media */

  async registerReconMediaAsync(caseId, newMediaInfo, jobInfo) {
    /* Registration of recon job into media */
    const config = {
      url: 'cases/' + caseId + '/media/' + newMediaInfo.id,
      data: {
        title: newMediaInfo.title,
        type: newMediaInfo.type.toLowerCase(),
        path: jobInfo.id,
        refs: {
          source_media: {
            media_id: newMediaInfo.media_id,
            path_id: newMediaInfo.path_id,
          },
        },
      },
    };
    return this.executeRegisterMedia(config);
  }

  async createReconJobAsync(caseId, newMediaInfo) {
    // TODO: remove me?
    // eslint-disable-next-line no-return-await
    return await jobClient.newReconJob(caseId, newMediaInfo);
  }

  async addReconMedia(caseId, newMediaInfo) {
    // Start a new reconstruction media job
    const jobInfo = await this.createReconJobAsync(caseId, newMediaInfo);
    return this.registerReconMediaAsync(caseId, newMediaInfo, jobInfo);
  }

  /* General */

  async createMedia(caseId, mediaInfo) {
    const url = 'cases/' + caseId + '/media/';
    const data = {
      title: mediaInfo.title,
      type: mediaInfo.type.toLowerCase(),
      created_time: new Date().toISOString(),
    };
    // eslint-disable-next-line no-return-await
    return await this.apiClient({
      url: url,
      method: 'post',
      data: data,
    }).then((response) => response.data);
  }

  async addMedia(caseId, mediaInfo, file) {
    let newMedia = await this.createMedia(caseId, mediaInfo);

    newMedia = {
      ...newMedia,
      ...mediaInfo,
    };

    newMedia.title = !newMedia.title ? newMedia.id : newMedia.title;

    const mediaType = mediaInfo.type.toLowerCase();

    if (mediaType.includes('ios')) {
      return this.addIOSMedia(caseId, newMedia, file);
    }
    if (mediaType.includes('reconstruct')) {
      return this.addReconMedia(caseId, newMedia);
    }
    if (mediaType.includes('monitoring')) {
      return this.addIOSMonitoringMedia(caseId, newMedia);
    }
    if (mediaType.includes('ct')) {
      return this.addCTMedia(caseId, newMedia, file);
    }
    return null;
  }

  async updateMedia(caseId, mediaId, newMediaInfo) {
    const url = 'cases/' + caseId + '/media/' + mediaId;
    return this.apiClient({
      url: url,
      method: 'put',
      data: newMediaInfo,
    }).then((response) => response.data);
  }

  async removeMedia(caseId, mediaId) {
    const url = 'cases/' + caseId + '/media/' + mediaId;
    return this.apiClient({
      url: url,
      method: 'delete',
    }).then((response) => response.data);
  }

  get_file_url({ caseId, mediaId, path }) {
    const url = baseMediaURL + '/media/' + caseId + '/' + mediaId + '/' + path;
    return url;
  }
}

const cases = new DTXRegistry();

export { cases, DTXRegistry };
